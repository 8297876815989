<template>
  <div class="viewer-box">

    <div v-if="loading" class="loading-img">
      <img src="../assets/loading.gif">
      <div class="msg">请稍后</div>
    </div>

    <error v-if="error_msg" :desc="error_msg"></error>

    <!--    zip-->
    <zip v-if="showZipViewer" :job_id="job_id" :open_id="open_id" :file-url="file_url" @callback="folderCallback"></zip>
    <!--    zip结束-->

    <!--    代码预览-->
    <div id="txtViewer"></div>
    <!--    代码预览结束-->

    <!--    图片预览-->
    <div v-if="showImageViewer" :style="{ height : height + 'px'  }">
      <vue-viewer :visible.sync="imageViewerConfig.visible" :images="images" :title="false" :movable="true"
                  :inline="imageViewerConfig.Inline" backdrop="static">
      </vue-viewer>
    </div>
    <!--    图片预览结束-->

    <!--    视频预览-->
    <div v-if="showVideoViewer" id="videoViewer">
      <video id="videoViewerPay" class="video-js vjs-big-play-centered" controls preload="auto">
      </video>
    </div>
    <!--    视频预览结束-->

    <!--    pdf预览-->
    <iframe v-if="showPdfViewer" :src="file_url" class="pdfViewer" :style="{  height : height + 'px'  }"></iframe>
    <!--    pdf预览 结束-->
    <html-components v-if="showHtmlViewer" :url="file_url" :job_id="job_id"></html-components>
  </div>

</template>

<script>

import viewer from "@/common/js/viewer";
import VueViewer from 'vue-viewerjs'
import Zip from "@/components/zip/zip";
import suffix from "@/common/js/suffix";
import Error from "@/components/error";
import {decode} from "@/common/js/base64";
import HtmlComponents from "@/components/html-components";
import tool from "@/common/js/tool";

export default {
  components: {
    HtmlComponents,
    Error,
    Zip,
    VueViewer
  },
  name: "viewer",
  data() {
    return {
      showHtmlViewer : false,
      showImageViewer: false,
      showVideoViewer: false,
      showPdfViewer: false,
      showZipViewer: false,
      imageViewerConfig: {
        Inline: true,
        visible: true
      },
      timer: null,
      file: '',
      file_url: "",
      images: [],
      suffix: this.$route.query.suffix,
      job_id: "",
      open_id : "",
      cache_id: this.$route.query.cache_id || "",
      path: this.$route.query.path,
      loading: true,
      height: 600,
      loading_error_number: 0,
      error_msg: '',
      // 配置信息
      option: {
        version: '',
        watermark_txt: '',
        watermark_angle: null,
        copy: 'enable',
        mouse_right: 'enable',
        callback:  ""
      }
    };
  },
  watch: {
    $route(to, from) {
      if (this.showZipViewer) {
        this.path = to.query.path || ""
        this.getDetailsByJobId("click")
      }
    },
  },
  mounted() {

  },
  methods: {
    /**
     * 文件夹点击事件
     * @param e
     */
    folderCallback(e) {
      //设置一下图像预览的模式
      if (e.file_loader === "imageViewer") {
        this.images = []
        this.showVideoViewer = true
        this.imageViewerConfig = {
          Inline: false,
          visible: true
        }
      }
      this.loadByFile(e.url, null, e.file_loader, false)
    },

    /**
     * 初始化配置信息
     * @param json
     */
    initByDefaultJobsConfig(json) {

      if (json.code !== 200)
        return this.error(json.msg)

      this.job_id = json.data.job_id
      this.cache_id = json.data.cache_id

      //如果存在配置项目
      if (json.data && json.data.option) {
        this.option = json.data.option
        viewer.init(this.option)
      }

      //根据状态码处理
      switch (json.data.status) {
        case -1 :
          return this.timer = setTimeout(() => {
            this.getDetailsByJobId()
          }, viewer.getTime(this.loading_error_number))
        case 1 :
          this.timer && clearTimeout(this.timer)
          return this.loadByFile(json.data.file_url, null, json.data.file_loader, false)
        case -2 :
        case -99 :
        default:
          return this.error(json.data.error_msg)
      }

    },

    /**
     * 检测状态
     */

    async getDetailsByJobId(type) {

      if (this.loading_error_number >= 100 && type !== "click")
        return this.error("文件预览失败，请稍后重试")

      let json = await viewer.getDetailsByJobId(this.job_id, this.cache_id, this.path)

      this.loading_error_number += 1
      //如果存在配置项目
      this.initByDefaultJobsConfig(json)

    },

    /**
     * 错误
     * @param msg
     */
    error(msg) {
      if (this.option.callback) {
        window.location.href = this.option.callback
        return;
      }
      this.error_msg = msg
      this.hideLoading()
    },
    /**
     * 隐藏加载
     * @param time
     */
    hideLoading(time) {
      this.timer && clearTimeout(this.timer)
      setTimeout(() => {
        this.loading = false
      }, time)
    },

    /**
     * 自动加载
     */
    loadByFile(file, custom_suffix, file_loader, createJob) {

      file_loader = file_loader ? file_loader : suffix.getFileLoader(file, custom_suffix)

      switch (file_loader) {
          //pdf预览
        case "pdf":
        case "pdfLoader" :
          this.hideLoading(1000)
          this.showPdfViewer = true
          this.file_url = `/dist/pdf-dist/viewer.html?file=${encodeURIComponent(file)}`
          viewer.success()
          break;

          // excel文件预览
        case "excelViewer" :
          this.hideLoading(1000)
            this.showHtmlViewer = true
          // this.showPdfViewer = true
          this.file_url = file
          viewer.success()
          break;

          //文本内容预览
        case "txtViewer" :
          viewer.viewerCode(file, () => {
            this.hideLoading(1000)
          })
          break;
          //zip预览
        case "listLoader" :
        case "zipViewer" :
          this.showZipViewer = true
          this.hideLoading()
          this.file_url = file
          viewer.success()
          break;

          //视频预览
        case "videoViewer":
          this.showVideoViewer = true
          viewer.video(file);
          break;

          //图片预览
        case "imageViewer" :
          this.showImageViewer = true
          this.images.push({
            url: file
          })
          viewer.success()
          break;
          //多图预览
        case "imageMore":
          this.showImageViewer = true
          this.images = JSON.parse(file);
          viewer.success()
          break;

          //其他
        default :
          createJob && this.createJob()
      }
    },
    /**
     * 创建任务
     */
    createJob() {

      let data = {
        job_path_id : this.$route.query.job_path_id || "",
        open_id: this.$route.query.open_id || "",
        file_url: this.file,
        file_suffix: this.suffix,
        file_loader: this.$route.query.file_loader || "",
        cache_time: this.$route.query.cache_time || "",
        version: this.$route.query.version || ""
      }

      this.api.post("/viewer/jobs/create", data, json => {
        this.initByDefaultJobsConfig(json)
      })
    },

    /**
     * 自动加载
     */
    autoLoad() {
      //如果存在任务id
      if (this.$route.query.job_id) {
        this.job_id = this.$route.query.job_id
        this.getDetailsByJobId()
      }
      //通过file 加载
      else if (this.$route.query.file) {
        this.file = this.$route.query.file
        this.loadByFile(this.file, this.suffix, null, true)
      }
      // 通过code 加载
      else if (this.$route.query.code) {
        this.file = decode(this.$route.query.code)

        // let suffix = tool.getExtension(this.file)
        // if (suffix=="")
        let file_loader =  null
        //如果是文本
        if (suffix.isTxtViewer(this.file)){
          file_loader = "txtViewer"
          this.file = encodeURIComponent(this.file)
        }

        this.loadByFile(this.file, this.suffix, file_loader, true)
      }
      else {
       this.error("请先提供要预览的文件")
      }
    }

  },
  created() {

    this.autoLoad()
    this.height = document.documentElement.clientHeight
    this.option.callback = this.$route.query.callback || ""
    this.open_id = this.$route.query.open_id || ""

    //配置项目
    this.option.version = this.$route.query.version || ""
    this.option.watermark_txt = this.$route.query.watermark_txt || ""
    this.option.watermark_angle = this.$route.query.watermark_angle || ""
    this.option.copy = this.$route.query.copy || "enable"
    this.option.mouse_right = this.$route.query.mouse_right || "enable"
    this.option.callback = this.$route.query.callback || ""
  }

};
</script>
<style scoped>
.vue_viewer {
  display: none !important;
}

#viewer_video {
  display: none;
}

.pdfViewer {
  width: 100%;
  height: 100%;
  border: none
}
</style>
