export default  {

    /**
     * 加载js
     * @param url
     * @param functionSuccess
     */
    js(url, functionSuccess){
        const scriptHeader = document.createElement("script");
        scriptHeader.type = "text/javascript";
        scriptHeader.src = url;
        document.body.appendChild(scriptHeader);
        scriptHeader.onload = () => {
            if (functionSuccess)
                functionSuccess()
        }
    },
    css(url){
        const scriptHeader = document.createElement("link");
        scriptHeader.type = "text/css";
        scriptHeader.href = url;
        scriptHeader.rel = "stylesheet";
        document.body.appendChild(scriptHeader);
    }
}
