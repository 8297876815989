<template>
  <modal name="iframe" :width="width"
         :height="height"
         :adaptive="true">
    <div class="close-model" >
      <div class="close-model-img" @click="hide"><img src="@/assets/image/back.png" class="ico-back" >返回</div>
      <div class="close-model-right" @click="hide"><img src="@/assets/image/close.png" class="ico-back" style="width: 24px" ></div>
    </div>
    <div class="model-content">
      <iframe :src="src" style="width: 100%; height: 100%; border: none" :style="{
        height : height - 50 +'px'
      }"></iframe>
    </div>
  </modal>
</template>

<script>
export default {
  name: "iframeModal",
  props : ["src"],
  data() {
    return {
      path : this.$route.query.path,
      width : document.documentElement.clientWidth,
      height : document.documentElement.clientHeight
    }
  },
  methods : {
    show () {
      this.$modal.show('iframe');
    },
    hide () {
      this.$modal.hide('iframe');
    },
  }
}
</script>

<style scoped>

.close-model {
  background: #0a84ff; height: 50px; line-height: 50px; padding-left: 15px; padding-right: 12px; color: #ffffff; font-size: 15px; font-weight: 400
}

.close-model-img {
  cursor: pointer;
  width: 100px;
  float: left;
}


.close-model-right {
  cursor: pointer;
  width: 100px;
  float: right;
  text-align: right;
}
.ico-back {
  width: 20px;
  margin-right: 2px;  vertical-align: middle;
}
</style>