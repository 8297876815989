<template>
<div class="excel-html-box" v-html="html">

</div>
</template>

<script>
import axios from "axios";
import excelTool from "@/common/js/excelTool";

export default {
  name: "html-components",
  props : ["url","job_id"],
  data(){
    return {
      html : ""
    }
  },
  created() {
      this.getContent()
    },
  methods : {
    getContent() {
        let params = {
          method: 'get',
          url: this.url,
          timeout: 120 * 1000
        };
        axios(params).then(response => {
          this.html = response.data
          this.$nextTick(()=>{
            excelTool.handle(this.job_id)
          })
        }).catch(error => {
          console.log(error)
        });

    }
  }
}
</script>

<style >

.excel-html-box .nav h1,.excel-html-box  hr,.excel-html-box  .nav br,.excel-html-box  a h1 {
  display: none;
}
.excel-html-box .nav {
  overflow: auto;
}

.excel-html-box .nav a {
  float: left;
  margin-top: 5px;
  margin-left: 4px;
  font-size: 12px;
  padding: 2px 10px;
  min-width: 90px;
  max-width: 130px;
  line-height: 33px;
  height: 33px;
  text-align: center;
  border-width: 2px;
  border-style: solid;
  border-radius: 8px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  border-color: rgb(211, 211, 211);
  background-color: rgb(211, 211, 211);
  color: #585858;
  text-decoration:none
}
.excel-html-box .nav a.cur {
  border-color: rgb(255, 255, 255);
  background-color: rgb(255, 255, 255);
  color: green;
  font-weight: bold;
  text-decoration :underline;
}
</style>