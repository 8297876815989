import tool from "@/common/js/tool";
import api from "@/conf/js/api";
import axios from "axios";
import load from "@/conf/js/load";
import watermark from "watermark-common";
import router from '@/router'

export default {

    config : {},

    /**
     * 初始化
     * @param config
     */
    init(config) {

        this.config = config

    },

    /**
     * 获取时间
     * @param limit
     * @returns {number}
     */
    getTime(limit) {
        if (limit <= 10)
            return 1000

        if (limit <= 30)
            return 2000

        if (limit <= 50)
            return 5000

        return 8000;
    },


    /**
     * 获取详情
     * @param job_id
     * @param cache_id
     * @param path
     * @returns {Promise<unknown>}
     */
    async getDetailsByJobId(job_id,cache_id, path) {
        return await new Promise((resolve, reject) => {
            api.post("/viewer/jobs/getDetailsByJobId", {job_id: job_id, cache_id: cache_id,path: path}, json => {
                resolve(json)
            }, () => {
                resolve()
            })
        })
    },


    // loadExcelViewer(url) {
    //
    //
    //     let params = {
    //         method: 'get',
    //         url: decodeURIComponent(url),
    //         data: {},
    //         timeout: 120 * 1000,
    //         headers: {}
    //     };
    //
    //
    //     axios(params).then(response => {
    //
    //         console.log(response)
    //
    //     }).catch(error => {
    //         console.log(error)
    //     });
    //
    //
    //     document.getElementById("excelViewer").innerHTML = url
    //
    // }
    // ,

    path(args) {

        // var args = arguments,
        let result = [];
        for (var i = 0; i < args.length; i++)
            result.push(args[i].replace('@', 'dist/line-dist/scripts/'));
        return result
    },

    /**
     * 检测元素是否存在
     * @param className
     * @param fun
     * @returns {number}
     */
    checkElements(className, fun) {

        let elements = document.getElementsByClassName(className)[0]

        if (!elements)
            return setTimeout(() => {
                this.checkElements(className, fun)
            }, 1000)

        fun()
    },



    viewerCode(file, fun) {

        file = decodeURIComponent(file)

        let js = success => {
            let params = {
                method: 'get',
                url: file,
                timeout: 120 * 1000
            };

            axios(params).then(response => {
                document.getElementById("txtViewer").style.display = "none"
                let pre = document.createElement("pre");
                pre.innerHTML = response.data;

                let brush = tool.getExtension(file)
                pre.className = "brush:" + (brush ? brush : 'js')

                //文本处理下
                if (brush==='txt') {
                    pre.style.paddingLeft = "15px"
                }


                document.getElementById("txtViewer").appendChild(pre)
                SyntaxHighlighter.autoloader.apply(null, path);
                SyntaxHighlighter.defaults['toolbar'] = false;
                SyntaxHighlighter.defaults['auto-links'] = true;
                SyntaxHighlighter.defaults['quick-code'] = true;
                SyntaxHighlighter.all();

                let success = () => {
                    document.getElementById("txtViewer").style.display = "block"
                    this.success()
                    fun && fun()
                }

               this.checkElements("gutter", success)

                setTimeout(success,  brush==='txt'?0:3000)

            }).catch(error => {
                console.log(error)
            });
        }

        let path = this.path([
            'applescript		@shBrushAppleScript.js',
            'actionscript3 as3		@shBrushAS3.js',
            'bash shell		@shBrushBash.js',
            'coldfusion cf		@shBrushColdFusion.js',
            'cpp c			@shBrushCpp.js',
            'c# c-sharp csharp		@shBrushCSharp.js',
            'css			@shBrushCss.js',
            'delphi pascal		@shBrushDelphi.js',
            'diff patch pas		@shBrushDiff.js',
            'erl erlang		@shBrushErlang.js',
            'groovy			@shBrushGroovy.js',
            'java			@shBrushJava.js',
            'jfx javafx		@shBrushJavaFX.js',
            'js jscript javascript	@shBrushJScript.js',
            'perl pl			@shBrushPerl.js',
            'php			@shBrushPhp.js',
            'text plain		@shBrushPlain.js',
            'py python			@shBrushPython.js',
            'ruby rails ror rb		@shBrushRuby.js',
            'sass scss			@shBrushSass.js',
            'scala			@shBrushScala.js',
            'sql			@shBrushSql.js',
            'vb vbnet asp			@shBrushVb.js',
            'xml xhtml xslt html htm		@shBrushXml.js'
        ])


        if(typeof(SyntaxHighlighter)=="function"){
          return   js
        }
        load.css("dist/line-dist/styles/shCore.css")
        load.css("dist/line-dist/styles/shCoreDefault.css")
        load.js("dist/line-dist/scripts/shCore.js", () => {
            load.js("dist/line-dist/scripts/shAutoloader.js", js)
        })
    },

    video(file) {

        load.css("dist/video-dist/video-js.min.css")
        load.js("dist/video-dist/video.min.js", () => {
            let player = videojs('videoViewerPay', {});
            player.src(file);
            player.height(document.documentElement.clientHeight);
            player.width(document.documentElement.clientWidth);
            document.getElementById("videoViewer").style.display = "block"
            this.success()
        })

    },
    /**
     * 加载成功事件
     */
    success() {

        setTimeout(() => {

            let watermarkConfig= {
                watermark_txt:  this.config.watermark_txt ? this.config.watermark_txt : (router.app.$route.query.watermark_txt?router.app.$route.query.watermark_txt : " "),
                watermark_angle: this.config.watermark_angle ? this.config.watermark_angle : router.app.$route.query.watermark_angle
            }

            watermark.init(watermarkConfig)

            watermark.load(watermarkConfig)

            if(this.config.copy==="disable")
                document.onselectstart = new Function("event.returnValue=false");
            if(this.config.mouse_right==="disable")
             document.oncontextmenu = function(){ return false;  }

        }, 100)
    }
}