<template>
  <div class="zip-box">
    <div class="table-box">
      <table class="table">
        <tr class="title">
          <th>名称</th>
          <th width="100">大小</th>
          <th width="180" class="hidden-phone">修改时间</th>
          <th width="100"  class="hidden-phone">操作</th>
        </tr>
        <tr v-if="$route.query.path">
          <td colspan="4" style="cursor: pointer" @click="back"><img src="@/assets/image/backward.png" class="ico-back">返回上一页
          </td>
        </tr>
        <tr v-if="fileUrl.length===0">
          <td colspan="4" style="text-align: center; font-size: 13px; color: #aaaaaa" @click="back">该文件夹下没有文件</td>
        </tr>
        <tr v-for="(item,index) in fileUrl" :key="index">
          <td>
            <div class="click-file" @click="click(item)"><img :src="'/static/suffix/'+getIco(item)+'.png'" class="ico">
              {{ item.file_name }}
            </div>
          </td>
          <td>{{ item.file_size ? item.file_size : "-" }}</td>
          <td class="hidden-phone">{{ item.file_time ? item.file_time : "-" }}</td>
          <td class="hidden-phone">-</td>
        </tr>
      </table>
    </div>

    <iframe-modal ref="iframeModal" :src="src" v-if="src"></iframe-modal>

  </div>
</template>

<script>
import IframeModal from "@/components/iframeModal";
import suffix from "@/common/js/suffix";

export default {
  name: "zip",
  components: {IframeModal},
  computed : {
    getIco() {
      return function (item) {
        if (item) {
          if (item.file_ico)
            return item.file_ico
          if (item.file_url)
            return suffix.getIco(item.file_url)
          return "file"
        }
      }
    }
  },
  props: ["fileUrl","job_id","open_id"],
  data() {
    return {
      path: this.$route.query.path,
      src: ''
    }
  },
  methods: {


    back() {

      let arr = this.$route.query.path.split('/')

      let r = arr.filter(function (s) {
        return s && s.trim();
      });
      r.pop()

      let path = ""
      r.forEach(item => {
        path += item + "/"
      })

      this.$router.push(`/viewer?job_id=${this.job_id}&path=${path}`)


      this.$emit("back")

    },
    click(item) {


      if (item.status && item.status !== 1)
        return this.$swal({
          icon: 'error',
          title: '该文件暂不支持预览',
          text: '请选择其他文件查看',
        })


      if (item.type==="folder")
        return   this.$router.push(`/viewer?job_id=${this.job_id}&path=${item.file_path}`)


       let file_loader = suffix.getFileLoader(item.file_url)

       if (file_loader==="imageViewer" )
         return this.$emit("callback", {
           file_loader : file_loader,
           url : item.file_url
         })

      this.src = `/#/viewer?file=${encodeURIComponent(item.file_url)}&job_path_id=${this.job_id}`
      this.$nextTick(() => {
        this.$refs.iframeModal.show()
      })


    }
  }
}
</script>

<style scoped>

.zip-box {
  max-width: 1000px;
  width: 100%;
  border: 1px solid #f0f0f0;
  border-bottom: none;
  border-radius: 3px;
  margin: 0px auto 0;
}


.zip-box td {
  padding: 14px;
  vertical-align: middle
}

.zip-box th {
  -webkit-text-size-adjust: 100%;
  font-family: Tahoma, Helvetica, "microsoft yahei", "Hiragino Sans GB", Simsun, \5b8b\4f53, sans-serif;
  font-size: 14px;
  border-collapse: collapse;
  border-spacing: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  outline: 0 !important;
  -webkit-tap-highlight-color: transparent !important;
  text-align: left;
  padding: 10px 10px;
  line-height: 1.42857143;
  border-bottom: 2px solid #f0f0f0;
  background-color: #fff;
  color: #333;
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: 800;
  border-top: 0;

}


.zip-box tr:hover {
  background: #f0f0f0;
}

.zip-box tr {
  border-bottom: 1px solid #f0f0f0;
}

.table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.table-box {
  -webkit-text-size-adjust: 100%;
  font-family: Tahoma, Helvetica, "microsoft yahei", "Hiragino Sans GB", Simsun, \5b8b\4f53, sans-serif;
  font-weight: 400;
  color: #585858;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  outline: 0 !important;
  -webkit-tap-highlight-color: transparent !important;
  overflow-x: auto;
  min-height: .01%;
  border: 0;
}

.title-viewer {
  position: relative;
}

.ico {
  width: 25px;
  margin-right: 5px;
  vertical-align: middle;
}

.ico-back {
  width: 20px;
  margin-right: 2px;
  vertical-align: middle;
}

.click-file {
  cursor: pointer;
}


@media screen and (max-width:650px) {

  .hidden-phone {
    display: none;
  }
}
</style>