import api from "@/conf/js/api";

export default {
    nowPage: 0,

    /**
     * 绑定名称
     * @param startNode
     * @param stopNode
     * @param page
     */
    bindClassByBetween(startNode, stopNode, page) {

        var elements = startNode.parentElement.childNodes;
        var startPos = -1;
        for (var i = 0; i < elements.length; ++i) {
            startPos = i;
            if (elements[i] === startNode) {
                break;
            }
        }

        for (var e = startPos; e < elements.length; ++e) {

            if (elements[e] === stopNode) {
                break;
            }

            // && elements[i].className.indexOf("table_class_") === -1
            if ((elements[e].nodeName === "IMG" || elements[e].nodeName === "TABLE")) {
                elements[e].className = elements[e].className + " table_class_" + page
                if (page > 0)
                    elements[e].style.display = "none"
            }

        }


    },
    /**
     * 操作元素
     * @param className
     * @param display
     */
    display(className, display) {
        document.querySelectorAll(className).forEach(e => (e.style.display = display));
    },
    removeClassName(query, className) {
        document.querySelectorAll(query).forEach(e => {
            if (e.className === className)
                e.className = ''
        });
    },

    /**
     * 处理nav
     */
    initNav() {

        var center = document.getElementsByTagName("center")[0];
        center.style.position = "fixed";
        center.style.left = "0";
        center.style.right = "0";
        center.style.bottom = "0";
        center.style.background = "#e3e3e3";
        center.style.borderTop = "2px solid #dddddd";
        center.className = "nav"

        var a = document.getElementsByTagName("a");
        for (var i = 0; i < a.length; i++) {
            var name = a[i].getAttribute("name")
            if (name) {
                var no = name.replace(/table/g, '')
                document.getElementsByTagName("table")[no].className = "table_select table-" + no
                document.getElementsByTagName("table")[no].setAttribute("page", no)
            }
        }
    },
    /**
     *
     * 开始处理
     */
    handle(job_id) {

        this.getImage(job_id)

        this.initNav()

        this.bindClass()

        this.bindClick()
    },

    /**
     * 更新图片
     * @param job_id
     */
    getImage(job_id) {

        let img = document.getElementsByTagName("img");
        let srcArr = [];
        for (let i = 0; i < img.length; i++) {
            let src = img[i].getAttribute("src")
            if (src.includes("html_"))
                srcArr.push(src)
        }

        api.post("/viewer/jobs/getImageDetailsByJobId", {job_id: job_id, img: srcArr}, json => {
            if (json.code === 200) {
                for (let i = 0; i < img.length; i++) {
                    let oldSrc = img[i].getAttribute("src")
                    let newSrc = json.data.find(ele => ele.img === oldSrc);
                    if (newSrc)
                        img[i].setAttribute("src", newSrc.url)
                }
            }
        }, () => {

        })

    },
    /**
     * 绑定class
     */
    bindClass() {
        var table_select = document.getElementsByClassName("table_select");
        for (var i = 0; i < table_select.length; i++) {
            this.bindClassByBetween(table_select[i], table_select[i + 1], i)
        }
    },
    /**
     * 绑定点击事件
     *
     */
    bindClick() {

        document.querySelectorAll(".nav a")[0].className = "cur"

        const _this = this;
        var list = document.querySelectorAll(".nav a");
        for (var i = 0; i < list.length; i++) {
            list[i].setAttribute("page", i)
            list[i].removeAttribute("href")
            list[i].addEventListener('click', function (elements) {
                scrollTo(0, 0);
                var clickPage = elements.target.getAttribute('page')
                _this.display('.table_class_' + _this.nowPage, "none");
                _this.display('.table_class_' + clickPage, "block");
                _this.removeClassName("a", "cur");
                elements.target.className = "cur"
                _this.nowPage = clickPage
            });
        }
    }
}

