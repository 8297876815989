import viewer from "@/common/js/viewer";
import tool from "@/common/js/tool";

export default  {
    /**
     * 获取文件后缀
     * @param file
     * @returns {*}
     */
    getSuffix(file) {
        let suffix = "";
        try {
            suffix = (file) ? file.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[1].toLowerCase() : "";
        } catch (err) {
            suffix = "";
        }
        return suffix;
    },
    /**
     * 获取支持的类型
     * @returns {string}
     * @param file_url
     * @param file_loader
     */
    getFileLoader(file_url , file_loader) {

      let suffix =  file_loader ? file_loader : this.getSuffix(file_url)
        switch (suffix) {
            case "pdf":
                return "pdf"
            case "opus":
            case "ogv":
            case "mov":
            case "m4v":
            case "mkv":
            case "aac":
            case "caf":
            case "flac":
            case "oga":
            case "m3u8":
            case "mpd":
            case "wav":
            case "mp4":
            case "wma":
            case "mid":
            case "mp3":
            case "m4a":
                return "videoViewer"
            case "jpeg":
            case "jpg":
            case "gif":
            case "bmp":
            case "tif":
            case "tiff":
            case "image":
            case "webp" :
            case "svg" :
            case "png" :
                return "imageViewer"
            case "imageMore":
               return  "imageMore"
            default :
               return "note"
        }


    }
    ,
    isTxtViewer(file_url) {

        switch (this.getSuffix(file_url)) {
            case "s":
            case "java":
            case "asp":
            case "bat":
            case "bas":
            case "prg":
            case "cmd":
            case "txt":
            case "log":
            case "xml":
            case "htm":
            case "html":
            case "js":
            case "css":
            case "json":
            case "sql":
            case "vtt":
            case "ulpfec":
            case "rtf":
            case "rtx":
            case "richtext":
                return "txtViewer"
        }

    },

    getIco(file_name) {

        let suffix = this.getSuffix(file_name)
        switch (suffix) {
            case "pdf" :
                return "pdf"
            case "png":
            case "jpeg" :
            case "jpg" :
            case "gif" :
            case "webp" :
                return "image"
            case "doc" :
            case "docx" :
            case "wps" :
            case "dot" :
            case "wpt" :
            case "dotx" :
                return "word"
            case "xls" :
            case "xlsx" :
            case "et" :
            case "csv" :
            case "xlt" :


            case "ett" :
            case "xltx" :
            case "xlsb" :
            case "xlsm" :
            case "xltm" :
            case "ets" :

                return "excel"

            case "ppt" :
            case "pptx" :

            case "pot" :
            case "potx" :

            case "pps" :
            case "ppsx" :
                return "ppt"
            case "txt" :
                return "txt"

            case "zip" :
            case "rar" :
            case "zipx" :
            case "7z" :
                return "zip"

            default :
                return "file"

        }


    },
}