
export default  {





    in_array(need, array) {
        need = [need];
        return need.every(val => array.includes(val));
    },
    /**
     * 获取hash
     * @param url
     * @returns {string|*}
     */
    getHashUrl(url) {
        try {
            if (url.includes("?"))
                return MD5(url.split('?')[0])
            return  MD5(url)
        }
        catch (e) {
            return  MD5(url)
        }
    },

    /**
     * 获取文件名称 不带后缀
     */
    getFileName(file_path) {
        return file_path.replace(/(.*\/)*([^.]+).*/ig,"$2");
    },

    /**
     * 获取文件名称 带后缀
     * @param file_path
     * @returns {T}
     */
    getFileNameFull(file_path) {
        return (file_path.match(/[^\\/]+\.[^\\/]+$/) || []).pop()
    },

    /**
     * 获取文件后缀
     * @param file
     * @returns {*}
     */
    getExtension(file) {
        let suffix = "";
        try {
            suffix = (file) ? file.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[1].toLowerCase() : "";
        } catch (err) {
            suffix = "";
        }
        return suffix;
    },
    /**
     * 是否图片
     * @param suffix
     * @returns {boolean}
     */
    isImage(suffix) {
        return suffix === "png" || suffix === "jpeg" || suffix === "jpg" || suffix === "gif" || suffix === "webp"
    },
    /**
     * 是否办公文档
     * @param suffix
     * @returns {boolean}
     */
    isDocument(suffix) {
        return suffix === "pptx" || suffix === "ppt" || suffix === "xls" || suffix === "xlsx" || suffix === "webp"  || suffix === "doc"  || suffix === "docx"  || suffix === "pdf"
    },
    /**
     * 计算速度
     * @param e
     * @returns {{percent, speed: string}}
     */
    spend(e) {

        let speed = e.totalBytesSent
        let units = 'B/s';//单位名称
        if (speed / 1024 > 1) {
            speed = speed / 1024;
            units = 'KB/s';
        }
        if (speed / 1024 > 1) {
            speed = speed / 1024;
            units = 'M/s';
        }

        return {
            loading : true,
            progress : `已完成${e.progress}%`,
            msg : "上传中，速度" +   speed.toFixed(1) + ' ' + units
        }


    },
}